<template>
  <div class="change-phone">
    <!-- <Header /> -->
    <b-container class="change-phone-container">
      <b-row class="justify-content-md-start back-nav">
        <b-link href="/setting"
          ><b-icon class="mx-2" icon="chevron-left"></b-icon
          ><span>Kembali</span></b-link
        >
      </b-row>
      <b-row class="justify-content-center">
        <div>
          <b-card-group deck>
            <b-card>
              <h1 class="text-center">Ubah Nomor HP</h1>
              <template>
                <div>
                  <form @submit.prevent="handleSubmit">
                    <h3 class="login-label">Nomor HP Baru</h3>

                    <vs-input
                      id="mobile_phone"
                      type="number"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      v-model="mobile_phone"
                      :placeholder="user.individual.mobile_phone"
                      required
                    />
                    <!-- <div class="error my-2" v-if="phoneIsExist">
                      <p>No. Hp sudah terdaftar.</p>
                    </div> -->
                    <!-- <div class="error my-2" v-if="phoneAndEmailIsExist">
                      <p>Email dan No. Hp sudah terdaftar.</p>
                    </div> -->

                    <div class="error my-2" v-if="!$v.mobile_phone.minLength">
                      <p>
                        No. Hp minimal
                        {{ $v.mobile_phone.$params.minLength.min }} digit.
                      </p>
                    </div>

                    <div class="error my-2" v-if="!$v.mobile_phone.maxLength">
                      <p>
                        No. Hp maksimal
                        {{ $v.mobile_phone.$params.maxLength.max }} digit.
                      </p>
                    </div>

                    <!-- <div class="my-2" v-if="failedChangePhone">
                      <p>
                        Email dan password tidak sesuai <br />
                        Silahkan cek kembali email dan password Anda
                      </p>
                    </div> -->

                    <div class="pt-4">
                      <b-button
                        block
                        :disabled="isBtnDisabled || loading"
                        type="submit"
                        @click="handleSubmit()"
                        class="btn-tertiary py-2"
                      >
                        <div v-if="loading">
                          <div class="spinner-border spinner-border-sm"></div>
                          Loading
                        </div>
                        <div v-else>Submit</div>
                      </b-button>
                    </div>
                  </form>
                </div>
              </template>
            </b-card>
          </b-card-group>
        </div>
      </b-row>

      <div class="modal-change-phone">
        <b-modal
          id="modal-success-change-phone"
          v-model="successChangePhone"
          size="md"
          class="modal-success-change-phone"
          centered
          hide-footer
          hide-header
          no-close-on-backdrop
        >
          <div class="d-block text-center">
            <img
              src="@/assets/img/icons/transactions/checked-blue.svg"
              alt=""
            />
            <h1 class="p-4 my-2 text-dark">No. Hp Berhasil Diubah</h1>
            <b-row class="justify-content-md-end btn-submit-row mb-4">
              <div class="col-lg-12 col-md-6">
                <b-button
                  size="sm"
                  block
                  class="btn-tertiary px-4 py-2"
                  href="/profile"
                  >Lihat Profil</b-button
                >
              </div>
            </b-row>
          </div>
        </b-modal>
      </div>

      <div class="modal-change-phone">
        <b-modal
          id="modal-success-change-phone"
          v-model="failedChangePhone"
          size="lg"
          class="modal-success-change-phone"
          centered
          hide-footer
          no-close-on-backdrop
        >
          <div class="d-block text-center">
            <img src="@/assets/img/ekyc/failed.svg" alt="" />
            <h1 class="p-4 my-2 text-dark">
              Ups! <br />
              Terjadi kesahan
            </h1>
            <p class="text-dark">
              Nomor HP tidak boleh sama dengan sebelumnya. Silahkan hubungi
              customer care
            </p>
            <b-row class="justify-content-center">
              <b-button
                href="https://api.whatsapp.com/send?phone=6287777936468"
                block
                class="mt-3 mx-2 btn-tertiary py-2"
                >Hubungi Customer Care</b-button
              >
            </b-row>
          </div>
        </b-modal>
      </div>
    </b-container>
    <!-- <Footer4 /> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { minLength, maxLength } from "vuelidate/lib/validators";

import axios from "axios";

const instance = axios.create({
  withCredentials: true,
});

export default {
  name: "ChangePhone",
  metaInfo: {
    title: "Ganti Nomor HP | CGS iTrade Fund",
  },
  data() {
    return {
      successChangePhone: false,
      failedChangePhone: false,
      mobile_phone: "",
      loading: false,
      processing: false,
    };
  },
  computed: {
    ...mapGetters({
      app_url: "app_url",
      user: "auth/user",
      check: "auth/check",
    }),
    ...mapActions({
      signout: "auth/signout",
    }),
    isBtnDisabled() {
      return this.mobile_phone.length === 0;
    },
  },
  validations: {
    mobile_phone: {
      minLength: minLength(10),
      maxLength: maxLength(13),
    },
  },
  methods: {
    handleSubmit() {
      if (!this.processing) {
        this.processing = true;
        let data = new FormData();
        data.append("mobile_phone", this.mobile_phone);

        let axiosConfig = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        instance
          .post(
            `${this.app_url}individual-account/change-mobile-phone`,
            data,
            axiosConfig
          )
          .then(() => {
            this.loading = !false;
            this.successChangePhone = true;
            this.processing = false;
          })
          .catch(() => {
            this.failedChangePhone = true;
            this.processing = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.change-phone {
  background: #f3f4f6;
}
.change-phone-container {
  padding-top: 140px;
  padding-bottom: 140px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;

  color: #111827;
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  color: #4b5563;
}

p {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #e53e3e;
}

span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #4f566b;
}

.error {
  p {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #e53e3e;
  }
}

.back-nav {
  padding: 0px 240px 20px 308px;

  a {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #6b7280;
    text-decoration: none;
  }
}

.vs-con-input-label {
  width: 100%;
}

.change-phone-label {
  padding-top: 16px;
}

.change-phone-link {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #6b7280;
  text-decoration: none;
}

.custom-control-label {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #3c4257;
}

.custom-control-label:before {
  background-color: #e60012 !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e60012 !important;
}

.card-body {
  padding: 48px;
  box-shadow: 0px 10px 15px rgba(107, 114, 128, 0.1),
    0px 4px 6px rgba(107, 114, 128, 0.05);
  border-radius: 5px;
  width: 500px;
}

.form-control:focus {
  outline: none !important;
  border: 1px solid !important;
  box-shadow: none !important;
}

@media only screen and (min-width: 1366px) {
  .change-phone-container {
    padding-bottom: 300px;
  }
}

@media only screen and (max-width: 768px) {
  .change-phone-container {
    padding-bottom: 60px;
  }
  .back-nav {
    padding: 0px 240px 20px 0px;
  }
  .card-body {
    width: 100%;
  }
}
</style>